form {
    width: 40%;
    margin: auto;
    background-color: aliceblue;
    border-radius: 20px;
    padding: 20px;

    section {
        margin: auto;
        display: flex;
        align-items: center;

        #date,
        #time,
        #event {
            appearance: none;
        }

        input,
        textarea,
        select {
            border-radius: 10px;
            border-style: none;
            background-color: gainsboro;
            width: 100%;
            margin: 10px;
            padding: 17px;
        }

        #item1,
        #item2,
        #item3 {
            padding: 15px;
            border-radius: 10px;
        }

        #item2 {
            background-color: chocolate;
        }

        #item3 {
            background-color: darkgoldenrod;
        }

        #check-button {
            width: auto;
        }

        .check-input {
            display: flex;
            align-items: center;
        }

        .package-item {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 15px;
            border-radius: 10px;
            transition: background-color 0.3s ease, box-shadow 0.3s ease,
                outline 0.3s ease;
            cursor: pointer;

            span {
                display: flex;
                flex-direction: column;

                p {
                    margin: 5px 0;
                }
            }

            &.selected {
                background-color: #4caf50;
                box-shadow: 0 0 10px rgba(78, 78, 78, 0.9);
                outline: 4px solid deepskyblue;
                outline-offset: -4px;
            }

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }
    }

    #checks {
        display: grid;
        width: 40%;
        grid-template-columns: 1fr 1fr;
    }

    button {
        padding: 15px 30px;
        border-radius: 10px;
        border: none;
        background-color: skyblue;
        cursor: pointer;

        &:active {
            background-color: white;
            border: 2px solid skyblue;
        }
    }

    span {
        display: block ruby;
    }

    @media only screen and (max-width: 480px) {
        width: 75%;

        .package-item span p {
            margin: 5px 0;
            font-size: 12px;
        }
    }
}

.formdata {
    width: 100%;
    margin: 50px 0;

    #sent {
        width: 30%;
        margin: auto;
        color: darkgreen;
        background-color: lightgreen;
        border-radius: 5px;
        padding: 15px 0;
    }
}

#in,
#out {
    height: auto;
    width: auto;
    margin: revert;
}

#travel-fee {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 13px;
    opacity: 50%;
}
