.home {
    display: flex;
    flex-direction: column;
}

.home .home-header {
    display: block;
    margin: 40px 0px;
}

.home .home-header #logopop {
    width: 540px;
    z-index: 10;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    animation: load 0.7s linear;
}

.home .home-header .carousel {
    margin: auto;
    width: 100%;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding: 50px 0;
    animation: load 5s linear;
}

.home .home-header .carousel-track {
    display: flex;
    width: calc(625px * 12);
    animation: scroll 40s linear infinite;
    overflow: hidden;
    border: 5px solid skyblue;
}

@keyframes load {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-625px * 6));
    }
}

.home .home-header .slide {
    height: 420px;
    width: 625px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.home .home-header .carousel::before,
.carousel::after {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.home .home-header .carousel::before {
    left: 0;
    top: 0;
}

.home .home-header .carousel::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.home .home-header .carousel img {
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.wrap {
    display: block;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
}

.wrap hr {
}

.wrap #events {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.wrap #flavors {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
}

.wrap #flavors,
.wrap #events {
    list-style: none;
    padding-left: 0;
}

.wrap #events li {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wrap #events li p {
    color: white;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    -webkit-text-stroke: 0.5px black;
}

.wrap #flavors li p,
.wrap #events li p {
    margin: 40px 0;
    font-size: 43px;
    font-weight: bold;
}

.wrap a {
    padding: 15px 30px;
    border-radius: 10px;
    border: none;
    background-color: skyblue;
    cursor: pointer;
    text-decoration: none;
    color: black;
    margin: 20px;
}

.wrap a #link {
    cursor: pointer;
    padding: unset;
    border: unset;
    background-color: unset;
}

.wrap a:active {
    background-color: white;
    border: 2px solid skyblue;
}

.about {
    display: block;
    width: 80%;
    margin: auto;
}

.about .pic-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about .pic-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.about .pic-bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.about .pic-bottom .img img {
    width: 950px;
}

.about .pic-left .img img,
.about .pic-right .img img {
    width: 450px;
    border: 5px solid skyblue;
}

.about .pic-left .text,
.about .pic-right .text,
.about .pic-bottom .text {
    text-align: left;
    margin: 0px 40px;
    font-size: 18px;
}

.shop .flavors {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.shop .indflav {
    text-align: left;
    padding: 0px 60px;
}

@media only screen and (max-width: 1492px) {
    .wrap {
        width: 90%;
    }
    .wrap #flavors li p,
    .wrap #events li p {
        font-size: 27px;
    }
    .about .pic-left {
        flex-direction: column;
    }
    .about .pic-left .img img {
        width: 545px;
    }
    .about .pic-bottom .img img {
        width: 635px;
    }
}

@media only screen and (max-width: 480px) {
    .wrap #flavors li p,
    .wrap #events li p {
        font-size: 17px;
    }
    .shop .flavors {
        grid-template-columns: none;
    }
    .home .home-header #logopop {
        width: 350px;
        top: 400px;
    }
    .home .home-header .carousel::before,
    .carousel::after {
        width: unset;
        background: unset;
    }
    .about .pic-left {
        flex-direction: column;
    }
    .about .pic-left .img img {
        width: 325px;
    }
    .about .pic-bottom .img img {
        width: 360px;
    }
}
