@font-face {
    font-family: future;
    src: url('../font/FuturaLT-Bold.ttf');
}

header {
    display: flex;
    width: 100%;
    background-color: deepskyblue;
    height: 183px;

    .content {
        display: flex;
        width: 65%;
        height: inherit;
        margin: auto;

        .logo {
            display: block;
            text-align: center;

            img {
                width: 157px;
                margin: 15px;
            }
        }

        .links {
            display: flex;
            margin: auto;
            margin-right: 0;

            nav {
                display: flex;

                li {
                    list-style: none;
                    margin: 0px 20px;
                    color: white;
                    font-size: 30px;

                    a {
                        color: white;
                        text-decoration: none;
                        font-family: future;

                        &:hover {
                            color: white;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        height: 271px;

        .content {
            flex-direction: column;
            width: 100%;
        }

        .links {
            margin: unset;
            align-self: center;
        }
    }
}

footer {
    display: flex;
    flex-direction: column;
    background-image: url('../img/preview.jpg');
    background-size: cover;
    background-repeat: repeat-y;
    width: 100%;
    background-position: 50% bottom;

    hr {
        width: 70%;
        border: 1px solid skyblue;
    }

    img {
        width: 16%;
    }
}
